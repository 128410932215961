<template>
    <div class="container admin-list">
        <div class="row">
            <div class="input-field col s12 m3">
                <input id="Email" type="text" v-model="queryStringData.search.email">
                <label for="Email" class="active">Email</label>
            </div>
            <div class="input-field col s12 m3">
                <input id="App" type="text" v-model="queryStringData.search.app">
                <label for="App" class="active">Application</label>
            </div>
            <div class="col s6">
                <label for="roleId" class="active">Role</label>
                <vue-select v-model="queryStringData.search.roleId" :reduce="(v)=>{return v.id}" label="name" :options="roles"></vue-select>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
                <button class="btn" @click="onSearch"><i class="material-icons">refresh</i></button>
                <button class="btn" @click="onAdd"><i class="material-icons">add_circle</i></button>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
                <div class="progress" v-if="dataLoading">
                    <div class="indeterminate"></div>
                </div>
                <vuetable ref="vuetable" :class="{loading:dataLoading}"
                    :css="tableCss"
                    :api-url="apiURL"
                    :http-fetch="getData"
                    http-method="post"
                    :load-on-start="false"
                    :fields="columns"
                    :sort-order="queryStringData.sort"
                    :per-page="dataPerPage"
                    :row-class="onRowClass"
                    @vuetable:loading="loading"
                    @vuetable:loaded="loaded"
                    @vuetable:load-success="loadSuccess"
                    @vuetable:load-error="loadError"
                    data-path="payload.items"
                    pagination-path="payload.pagination"
                    @vuetable:pagination-data="onPaginationData"
                    @VuetableRowAction:action-item="onRowAction">
                    <template slot="languages" scope="props">
                        <div>
                            ({{props.rowData.languagesCount}}/{{languages.length-1}})
                            {{props.rowData.languageCodes}}
                        </div>
                    </template> 
                </vuetable>
                
            </div>
            <div class="col s12">
            <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="paginationCss">
            </vuetable-pagination>
            </div>
            
        </div>
    </div>
    
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import _ from 'underscore';
import moment from 'moment';
import settings from '../settings'
import globals from '../globals';
import Vuetable from '../../../node_modules/vuetable-2/src/components/Vuetable.vue'
import VuetablePagination from '../../../node_modules/vuetable-2/src/components/VuetablePagination.vue'
import VuetableRowActions from '../components/VuetableRowActions.vue';

Vue.component('vuetable-raw-actions', VuetableRowActions)

const itemName='approle';
const itemNamePlural='approles';
const searchParams={
    email:'',
    roleId:undefined,
    app:''
};
const intialSort=[{
    field:'id',
    direction:'DESC'
}]
const tableColumns=[
    {
        name: 'id',
        title:'Id',
        sortField: 'id',
        titleClass: 'id-column',
        dataClass: 'id-column',
        // callback: 'gender'
    },
    {
        name: 'email',
        title:'Email',
        sortField: 'email',
    },
    {
        name: 'app',
        title:'Application',
        sortField: 'app',
    },
    {
        name: 'roleId',
        title:'RoleId',
        sortField: 'roleId',
        callback:(v)=>{
            if (v===null || v===undefined)
                return '-';
            return _.findWhere(settings.roles,{id:v}).name;
        }
    },
    {
        name: '__component:vuetable-raw-actions',  
        title: 'Actions',
        // titleClass: 'center aligned',
        // dataClass: 'center aligned'
    }
];
const deleteConfirmationField='id';
const defaultNewItem={
    app:'',
    userId:undefined,
    roleId:0
}

export default {
    data(){
        return {
            roles:_.filter(settings.roles,(r)=>r.app!==''),
            routeURL:'/'+itemNamePlural,
            editURL:'/'+itemNamePlural+'/',
            apiURL:`${settings.serviceUrl}${itemName}/getMany`,
            deleteAPIURL:`${settings.serviceUrl}${itemName}/remove`,
            createAPIURL:`${settings.serviceUrl}${itemName}/add`,
            queryStringData:{
                search:searchParams,
                sort:[]
            },
            columns:tableColumns,
            paginationCss:{
                icons:{
                    first: 'material-icons first_page',
                    prev: 'material-icons chevron_left',
                    next: 'material-icons chevron_right',
                    last: 'material-icons last_page',
                },
                pageClass: 'page-item',
                activeClass:'active'
            },
            tableCss:{
                tableClass:'striped responsive-table',
                ascendingIcon:  {
                    params:['material-icons right','arrow_drop_down']
                },
                descendingIcon: {
                    params:['material-icons right','arrow_drop_up']
                },
                renderIcon:function(classes){
                    if (classes[1])
                        return `<i class="${classes[0]} ${classes[1].params[0]}"> ${classes[1].params[1]}</i>`;
                    else
                        return `<i class="${classes[0]}"></i>`;
                }
            },
            dataPerPage:settings.dataPerPage,
            dataLoading:false, 
            selectedElementId:undefined,
            currentQueryStringParams:''
        };
    },
    components:{
        Vuetable,
        VuetablePagination
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$store.state.adminEditPageId){
                vm.selectedElementId=vm.$store.state.adminEditPageId;
                vm.$store.commit('setAdminEditPageId',undefined);
            }
            if (vm.$route.query.search){
                let searchArgs=vm.$route.query.search.split('|');
                for (let sa of searchArgs){
                    if (sa){
                        let qArgs=sa.split(',');
                        vm.queryStringData.search[qArgs[0]]=parseInt(qArgs[1]);
                    }
                }
            }
            if (vm.$route.query.sort){
                let sortArgs=vm.$route.query.sort.split('|');
                for (let sa of sortArgs){
                    if (sa){
                        let sArgs=sa.split(',');
                        vm.queryStringData.sort.push({
                            field:sArgs[0],
                            direction:sArgs[1]
                        });
                    }
                }
            }
        })
        
    },
    mounted(){
        this.updateURLWithSearchAndSortData=()=>{
            let sort=this.$refs.vuetable.getSortParam();
            let searchParams="";
            for (let s in this.queryStringData.search){
                if (this.queryStringData.search[s]!=undefined && this.queryStringData.search[s]!=='')
                    searchParams+=`|${s},${this.queryStringData.search[s]}`;
            }
            if (searchParams)
                searchParams=searchParams.substr(0);

            let sortParams="";
            for (let s of this.$refs.vuetable.sortOrder)
                sortParams+=`|${s.field},${s.direction}`;
            if (sortParams)
                sortParams=sortParams.substr(0);
            
            this.currentQueryStringParams=JSON.stringify({search:this.$route.query.search,sort:this.$route.query.sort,page:parseInt(this.$route.query.page)});
            let query={search:searchParams,sort:sortParams,page:this.$refs.vuetable.currentPage};
            if (this.currentQueryStringParams!=JSON.stringify(query)){
                this.$router.push({query})
            }
        };
        if (this.$route.query.page){
            this.$refs.vuetable.currentPage =parseInt(this.$route.query.page);
        }
        this.$refs.vuetable.reload();
    },
    methods:{
        getData(apiUrl, httpOptions){
            let sort=[];
            for (let s of this.$refs.vuetable.sortOrder)
                sort.push({field:s.sortField,direction:s.direction});
            if (sort.length==0)
                sort=intialSort;
            return axios.post(apiUrl, {
                tokenValue:window.localStorage.getItem("token"),
                item:this.queryStringData.search,
                sort,
                itemExtra:{},
                page:{
                    page:httpOptions.params.page,
                    perPage:httpOptions.params.per_page,
                }
            });
        },
        async loadError(r){
            await globals.dialog.alert(`Error`,`${r.data.message}`);
        },
        async loadSuccess(r){
            if (r.data.code===-1001)
                this.$router.push('/signin');
        },
        loading(){
            this.dataLoading=true;
        },
        loaded(){
            if (this.selectedElementId){
                if (document.querySelector(".selected"))
                    document.querySelector(".selected").scrollIntoView();
            }
            this.dataLoading=false;
            
            this.updateURLWithSearchAndSortData();
        },
        onRowClass(data,index){
            if (this.selectedElementId){
                if (data.id==this.selectedElementId){
                    return "selected";
                }
            }
            return "";
        },
        async onRowAction(action,data){
            const self=this;
            if (data.action==='edit-item'){
                this.$store.commit('setAdminEditPageId',data.data.id);
                this.$router.push(this.editURL+data.data.id);
            }
            if (data.action==='delete-item'){
                let res=await globals.dialog.confirm('Confirmation needed',`<div>Are you sure you want to delete item  ${data.data[deleteConfirmationField]}?</div>`);
                if (res){
                    let response=await axios.post(`${this.deleteAPIURL}`,{
                        tokenValue:window.localStorage.getItem("token"),
                        id:data.data.id
                    });
                    this.$refs.vuetable.refresh();
                }
            }
        },
        onPaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage (page) {
            this.$refs.vuetable.changePage(page)
        },
        onSearch(){
            this.$refs.vuetable.refresh();
        },
        async onAdd(){
            this.$root.$emit('ui-blocker',1);
            let response=await axios.post(`${this.createAPIURL}`,{
                tokenValue:window.localStorage.getItem("token"),
                item:defaultNewItem
            });
            if (!response.data.ok){
                this.$root.$emit('ui-blocker',-1);
                await globals.dialog.alert('Λυπούμαστε :(',`Προέκυψε κάποιο πρόβλημα στην επικοινωνία με τον server ${response.data.message}`,()=>{},{messageType:'alert',language:'en'});
                return;
            }
            let newId=response.data.payload.id;
            this.$root.$emit('ui-blocker',-1);
            this.$router.push(this.editURL+newId);
        }
    }
}
</script>

<style lang="less">
td{
    padding:0px;
}
</style>
