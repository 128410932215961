var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "container",
          staticStyle: { "background-color": "#fafafa" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col s12" }, [
              _c("strong", [_vm._v("Connected as: " + _vm._s(_vm.userEmail))])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col s12" },
              [
                _c("router-link", { attrs: { to: "/", exact: "" } }, [
                  _vm._v("home")
                ]),
                _vm._v(" |\r\n        "),
                _vm.loggedIn
                  ? _c("router-link", { attrs: { to: "/users" } }, [
                      _vm._v("users |")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.loggedIn
                  ? _c("router-link", { attrs: { to: "/approles" } }, [
                      _vm._v("roles |")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loggedIn
                  ? _c("router-link", { attrs: { to: "/signin" } }, [
                      _vm._v("login")
                    ])
                  : _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onLogout($event)
                          }
                        }
                      },
                      [_vm._v("logout")]
                    )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("dialogs-wrapper"),
      _vm._v(" "),
      _c("ui-blocker"),
      _vm._v(" "),
      _c("error-modal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }