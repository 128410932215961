 <template>
    <div class="custom-actions">
      <!-- <button class="btn btn-smal btn-flat" @click="itemAction('view-item', rowData, rowIndex)"><i class="tiny material-icons">search</i></button> -->
      <button class="btn btn-smal btn-flat" @click="itemAction('edit-item', rowData, rowIndex)"><i class="tiny material-icons">edit</i></button>
      <button class="btn btn-smal btn-flat" @click="itemAction('delete-item', rowData, rowIndex)"><i class="tiny material-icons">delete</i></button>
    </div>
  </template>

  <script>
  export default {
    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    methods: {
      itemAction (action, data, index) {
          this.$parent.$emit('VuetableRowAction:action-item', 'action', {action: action, data: data,index:index});
      }
    }
  }
  </script>

  <style <style lang="less" scoped>
 
    .btn{
        padding:0px 5px;
        color:#777;
    }
  </style>