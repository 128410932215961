<template>
<div>
  <div class="container" style="background-color:#fafafa;">
    <div class="row">
      <div class="col s12">
        <strong>Connected as: {{userEmail}}</strong>
      </div>
      <div class="col s12">
        <router-link to="/" exact>home</router-link> |
        <router-link to="/users" v-if="loggedIn" >users |</router-link>
        <router-link to="/approles" v-if="loggedIn" >roles |</router-link>
        <router-link v-if="!loggedIn" to="/signin">login</router-link>
        <a href="" v-else @click.prevent="onLogout">logout</a>
      </div>
    </div>
  </div>
  <router-view></router-view>
  <dialogs-wrapper></dialogs-wrapper>
  <ui-blocker></ui-blocker>
  <error-modal></error-modal>
</div>
  
</template>

<style lang="less">
.router-link-active{
  text-decoration: none;
  color:black;
}
.dialog-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(0,0,0,.33);
    .dialog-content {
      min-width: 240px;
      text-align: center;
      font-size: 16px;
      margin: 0 20px;
      padding: 20px 30px;
      border: 1px solid #ebeef5;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      color: #303133;
      header{
        font-weight: bold;
        background-color: #ccc;
        padding: 10px;
        margin-bottom: 10px;
      }
    }

}
.v-select{
  .vs__dropdown-toggle{
    margin-top:10px;
    border-radius: 0px;
    border: 1px solid #9e9e9e;
  }
  input{
    border-bottom: none !important;
    height: auto !important;
    width: auto !important;
    border-radius:0px !important;
  }
  .vs__actions{
    svg{
      cursor: pointer;
    }
  }
}
.input-field .validation-error-message{
  color:red;
}
.admin-list{
  tr{
    &.selected{
      border: 1px solid #aaa;
      td{
        padding:5px 0px;
      }
    }
    td{
        padding:0px;
        &.id-column{
          text-align:right;
          padding-right:10px;
          font-weight: bold;
          color:darkgreen;
        }
    }
    th{
      &.id-column{
        text-align:right;
        padding-right:10px;
        font-weight: bold;
        color:darkgreen;
      }
    }
  }
}
</style>

<script>
import UIBlocker from './components/UIBlocker.vue'
import ErrorModal from './components/ErrorModal.vue';
import settings from './settings';

export default {
  components:{
      'ui-blocker':UIBlocker,
      'error-modal':ErrorModal
  },
  data () {
    return {
      token:localStorage.getItem('token')
    }
  },
  computed:{
    userEmail(){
      if (this.$store.state.user)
        return this.$store.state.user.email;

      return "-";
    },
    loggedIn(){
      return this.$store.state.user;
    }
  },
  mounted(){
    this.$root.$on('loggedIn',()=>{
      this.token=localStorage.getItem('token');
    })
  },
  methods:{
    async onLogout(){
      localStorage.removeItem('token');
      this.$store.commit('setUser',undefined);
      this.token=undefined;
      await axios.post(`${settings.serviceUrl}token/expireToken`, {tokenValue:this.token});
      this.$router.push('/signin');
    }
  }
}
</script>

