<template>
<div class="container">
    <div class="row">
        <form>
            <div class="input-field col s12 m6">
            <input id="email" type="text" v-model="username">
            <label for="email" class="active">Email</label>
            </div>
            <div class="input-field col s12 m6">
            <input id="password" type="password" v-model="password">
            <label for="password" class="active">Password</label>
            </div>
        </form>
        <button class="btn right" @click="onLogin">Submit</button>
        <div class="col s12" v-if="message" style="padding-top:10px">
            <div class="right" v-if="!ok" style="font-weight:bold;color:red">{{message}}</div>
            <div class="right" v-else style="font-weight:bold;">{{message}}</div>
        </div>   
    </div>
</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

import settings from '../settings.js';

export default {
    data() {
        return {
            username:'',
            password:'',
            message:'',
            ok:true
        }
    },
    components:{},
    mounted() {
        const self = this;
        
    },
    methods: {
        async onLogin(){
            this.message='Logging in...';
            let r=await axios.post(`${settings.serviceUrl}user/login`, {email:this.username,password:this.password,app:settings.app});
            this.ok=r.data.ok;
            if (!r.data.ok){
                this.message=r.data.message;
                return;
            }
            if (r.data.code===0){
                this.message=r.data.message;
                return;
            }
            this.message="SUCCESS";
            localStorage.setItem('token',r.data.payload.token.value);
            this.$store.commit('setUser',r.data.payload.user);
            this.$root.$emit('loggedIn');
            this.$router.push('/');
        }
    }
}
</script> 

<style lang="less">

</style>
