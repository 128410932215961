<template>
<div class="container">
    <div class="row">
        <div class="col s12">
            <div v-if="testing" style="font-weight:bold">Getting active tokens...</div>
            <div v-else style="font-weight:bold">Currently active tokens:</div>
            <div v-if="status===0">...</div>
            <div v-if="status===1" style="color:green;font-weight:bold">SUCCESS</div>
            <div v-if="status===-1" style="color:red;font-weight:bold">ERROR</div>
            <div v-if="message" v-html="message"></div>
        </div>
        <div class="col s12" v-if="this.tokens" style="margin-top:10px">
            <div v-for="t of this.tokens" :key="t.value" style="display:flex">
                <div style="flex:1 1 10%;margin:0px 5px">
                    <span v-if="t.lastActiveInMinutes<60 && !t.expired" class="btn-small red pulse " style="overflow:visible;text-align:center;min-width:100px">{{t.lastActiveInMinutes}}m</span>
                    <span v-if="t.lastActiveInMinutes>=60 && !t.expired" class="btn-small cyan " style="overflow:visible;text-align:center;min-width:100px">{{t.lastActiveInHours}}h</span>
                    <span v-if="t.expired" class="btn-small grey " style="overflow:visible;text-align:center;min-width:100px">x</span>
                </div>
                <div style="flex:1 1 40%;margin:0px 5px"><strong>{{t.app}}</strong> <br/> {{t.value}} <strong> <br/>({{t.email}})</strong></div>
                <div style="flex:1 1 30%;margin:0px 5px">
                    <span v-if="t.expired" style="color:red">Expired </span>
                    <span v-if="!t.expired" style="color:darkgreen">Will expire in </span>
                    {{t.expiresInMinutes|toDuration}}
                    <span v-if="t.expired" style="color:red"> ago </span>
                </div>
                <div style="flex:1 1 20%;">
                    <span style="cursor:pointer" @click="onRemoveToken(t)">remove</span> |
                    <span style="cursor:pointer" @click="onRefreshToken(t)">refresh</span>
                </div>
            </div>
        </div>
        <div class="col s12">
            <button class="btn right" @click="onRefresh">Refresh</button>
        </div>
        <div class="col s12">
            <div class="left">
                <div class="switch" style="margin-top:-10px">
                    <label>
                    Auto update
                    <input type="checkbox" v-model="autoUpdate">
                    <span class="lever"></span>
                    -
                    </label>
                </div>
            </div>
            <div class="right">Last update: {{lastUpdate | toDateString}}</div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment, { duration } from 'moment';
import _ from 'underscore';

import settings from '../settings.js';

export default {
    filters:{
        toDateString(value){
            return moment(value).format('DD/MM/YYYY HH:mm:ss');
        },
        toDuration(value){
            let v=Math.abs(value);
            let d=Math.floor(v/(24*60));
            let h=Math.floor((v-d*24*60)/60);
            let m=v-d*24*60-h*60;
            let s=``;
            if (d>0)
                s+=`${d} days`;
            if (h>0)
                s+=` ${h} hours`;
            if (m>0)
                s+=` ${m} minutes`;

            return s;
        }
    },
    data() {
        return {
            tokens: [],
            testing:true,
            status:0,
            message:'',
            lastUpdate:new Date(),
            autoUpdate:false,
            timerHandle:undefined
        }
    },
    components:{},
    mounted() {
        const self = this;
        this.getInfo();
    },
    watch:{
        autoUpdate(val){
            if (val){
                this.timerHandle=setInterval(async ()=>{
                    await this.getInfo();
                },2000)
            }
            else{
                if (this.timerHandle){
                    clearInterval(this.timerHandle);
                }
            }
        }
    },
    methods: {
        async onRemoveToken(t){
            let r=await axios.post(`${settings.serviceUrl}token/expireTokenOther`, {token:t.value,tokenValue:localStorage.getItem('token')});
            await this.getInfo();
        },
        async onRefreshToken(t){
            let r=await axios.post(`${settings.serviceUrl}token/tokenRefreshOther`, {token:t.value,tokenValue:localStorage.getItem('token')});
            await this.getInfo();
        },
        activeTokens(v){
            if (!v || v.length==0) return '-';
            return _.filter(v,(t)=> t.expiresInMinutes>0).length;
        },
        async getInfo(){
            this.testing=true;
            this.status=0;
            this.message='';
            await axios.post(`${settings.serviceUrl}getActiveTokens`, {tokenValue:localStorage.getItem('token')}).then((r) => {
                if (r.data.ok){
                    this.status=1;
                    this.testing=false;
                    this.tokens=r.data.payload;
                    
                    this.message=`Currently there are ${this.activeTokens(this.tokens)} active tokens`
                }
                else{
                    this.status=-1;
                    this.message=r.data.message+'<br/>'+JSON.stringify(r.data.payload);
                    this.testing=false;
                }
            });
            this.lastUpdate=new Date();
        },
        async onRefresh(){
            await this.getInfo();
        }
    }
}

</script> 

<style lang="less">

</style>
