<template>
<div id="error-modal" class="modal">
    <div class="modal-content">
        <h4>{{errorModalHeader}}</h4>
        <p>{{errorModalText}}</p>
    </div>
    <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Ενταξει</a>
    </div>
</div>
</template>

<script>
export default {
    name: "errorModal",
    data: () => {
        return {
            errorModalHeader: '-',
            errorModalText: '-'
        }
    },
    created() {},
    mounted() {
        const that = this;
        var elem = document.querySelector("#error-modal");
        this.modalInstance = M.Modal.init(elem, {});
        this.$root.$on("error-modal", (h,t) => {
            this.errorModalHeader=h;
            this.errorModalText=t;
        });
    },
    methods: {
        show(errorModalHeader, errorModalText) {
            this.errorModalHeader = errorModalHeader;
            this.errorModalText = errorModalText;
            this.modalInstance.open();
        }
    }
};
</script>
