var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-box dialog-mask" }, [
    _c("div", { staticClass: "dialog-content" }, [
      _c("header", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "dialog-body" }, [
        _c("p", [_vm._v(_vm._s(_vm.content))]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value"
            }
          ],
          attrs: { type: "text" },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.value = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("footer", [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "text" },
            on: {
              click: function($event) {
                return _vm.$close(_vm.value)
              }
            }
          },
          [_vm._v("Save")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "text" },
            on: {
              click: function($event) {
                return _vm.$close()
              }
            }
          },
          [_vm._v("Cancel")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }