var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function($event) {
          return _vm.$close(false)
        }
      }
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("header", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("div", { staticClass: "dialog-body" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
        ]),
        _vm._v(" "),
        _c("footer", [
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.$close(true)
                }
              }
            },
            [_vm._v("Ok")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }