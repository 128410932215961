var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("ValidationObserver", { ref: "validator" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s12" }, [
                _vm._v("\r\n            Id: " + _vm._s(_vm.id) + " "),
                _c("br"),
                _c("br")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-field col s12" },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required|email", immediate: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.email,
                                    expression: "item.email"
                                  }
                                ],
                                staticClass: "validate",
                                class: {
                                  invalid: errors.length > 0,
                                  valid: errors.length == 0
                                },
                                attrs: { id: "email", type: "text" },
                                domProps: { value: _vm.item.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm._l(errors, function(e, idx) {
                                return _c(
                                  "span",
                                  {
                                    key: idx,
                                    staticClass:
                                      "helper-text validation-error-message"
                                  },
                                  [_vm._v(_vm._s(e))]
                                )
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2769190494
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "active", attrs: { for: "email" } },
                    [_vm._v("Email")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-field col s12" },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required", immediate: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.password,
                                    expression: "item.password"
                                  }
                                ],
                                class: {
                                  invalid: errors.length > 0,
                                  valid: errors.length == 0
                                },
                                attrs: { id: "password", type: "text" },
                                domProps: { value: _vm.item.password },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "password",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm._l(errors, function(e, idx) {
                                return _c(
                                  "span",
                                  {
                                    key: idx,
                                    staticClass:
                                      "helper-text validation-error-message"
                                  },
                                  [_vm._v(_vm._s(e))]
                                )
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3185947467
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "active", attrs: { for: "password" } },
                    [_vm._v("Password")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-field col s12" },
                [
                  _c(
                    "label",
                    { staticClass: "active", attrs: { for: "roleId" } },
                    [_vm._v("Role")]
                  ),
                  _vm._v(" "),
                  _c("vue-select", {
                    attrs: {
                      reduce: function(v) {
                        return v.id
                      },
                      label: "name",
                      options: _vm.roles
                    },
                    model: {
                      value: _vm.item.roleId,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "roleId", $$v)
                      },
                      expression: "item.roleId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col s12" }, [
                _c("div", { staticClass: "controls" }, [
                  _c(
                    "a",
                    {
                      staticClass: "waves-effect waves-light btn right",
                      on: { click: _vm.onSave }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("save")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "waves-effect waves-light btn right",
                      staticStyle: { "margin-right": "10px" },
                      on: { click: _vm.onCancel }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("cancel")
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }