var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12" }, [
        _vm.testing
          ? _c("div", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Testing connection with database")
            ])
          : _c("div", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Connection with database result:")
            ]),
        _vm._v(" "),
        _vm.status === 0 ? _c("div", [_vm._v("...")]) : _vm._e(),
        _vm._v(" "),
        _vm.status === 1
          ? _c(
              "div",
              { staticStyle: { color: "green", "font-weight": "bold" } },
              [_vm._v("SUCCESS")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.status === -1
          ? _c(
              "div",
              { staticStyle: { color: "red", "font-weight": "bold" } },
              [_vm._v("ERROR")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.message
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col s12" }, [
        _c(
          "button",
          { staticClass: "btn right", on: { click: _vm.onRefresh } },
          [_vm._v("Refresh")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col s12" }, [
        _c("div", { staticClass: "right" }, [
          _vm._v(
            "Last update: " + _vm._s(_vm._f("toDateString")(_vm.lastUpdate))
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }