import settings from './settings.js';

import "./assets/favicon.png"
import "./assets/logo.png"

import axios from 'axios';
import Vue from 'vue';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (settings.env!=='development'){
  Sentry.init({
    dsn:settings.sentryKey,
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
  });
}

import VueRouter from 'vue-router';
import * as ModalDialogs from 'vue-modal-dialogs'
import { create } from 'vue-modal-dialogs'
import VueSelect from 'vue-select'
import '../../node_modules/vue-select/dist/vue-select.css'

import { extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true,
  message: 'required'
});
extend('email', {
    ...email,
    message:'a valid email address is needed'
});


import globals from './globals.js';
import store from './store.js';

import App from './app.vue';
import home from './views/home.vue';
import users from './views/users.vue';
import user from './views/user.vue';
import approles from './views/approles.vue';
import approle from './views/approle.vue';
import login from './views/login.vue';

import Confirm from './components/Confirm.vue';
import Alert from './components/alert.vue';
import EditSingleValue from './components/editSingleValue.vue';



globals.dialog.confirm = create(Confirm, 'title', 'content')
globals.dialog.alert = create(Alert, 'title', 'content')
globals.dialog.editSingleValue=create(EditSingleValue, 'title', 'content','value');



Vue.use(VueRouter);
Vue.use(ModalDialogs);
Vue.component('vue-select',VueSelect);

const routes=[
  { path: '/', component: home, props: true},
  { path: '/users', component: users, props: true,meta:{auth:true}},
  { path: '/approles', component: approles, props: true,meta:{auth:true}},
  { path: '/approles/:id', component: approle, props: true,meta:{auth:true}},
  { path: '/users/:id', component: user, props: true,meta:{auth:true}},
  { path: '/signin', component: login, props: true,meta:{auth:false} }
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  let token=window.localStorage.getItem('token');

  if (!to.meta.auth) {
    if (!store.state.user && token){
      let r=await axios.post(`${settings.serviceUrl}token/getUserFromToken`, { tokenValue: token });
      if (r.data.code!=-1001)
        store.commit('setUser',r.data.payload);
    }
    next();
    return;
  }
  
  if (!token){
    next('/signin');
    return;
  }
  let r=await axios.post(`${settings.serviceUrl}token/tokenExpired`, { tokenValue: token });
  if (r.data.code <0) {
    localStorage.removeItem('token');
    next('/signin');
    return;
  }
  if (!store.state.user){
    let r=await axios.post(`${settings.serviceUrl}token/getUserFromToken`, { tokenValue: token });
      if (r.data.code!=-1001)
        store.commit('setUser',r.data.payload);
  }
  next();
});

let app=new Vue({
  el: '#app',
  render: h => h(App),
  components: { App },
  router,
  store,
  data: {},
  mounted() {
  },
  methods: {
  },
});

axios.interceptors.response.use(function (response) {
  if (response.data.code===-1001){ //No token found in database
    localStorage.removeItem('token');
    app.$store.commit('setUser',undefined);
    app.$router.push('/signin');
  }
  return response;
}, function (error) {
  if (settings.env!=='development'){
    Sentry.captureException(new Error(error));
  }
  return Promise.reject(error);
});