<template>
<div class="container" v-if="item">
    <ValidationObserver ref="validator">
    <div class="row">
        <div class="col s12">
            Id: {{id}} <br/><br/>
        </div>
        <div class="input-field col s12">
            
            <label for="email" class="active">Email</label>
            <vue-select v-model="item.userId" :reduce="(v)=>{return v.id}" label="email" :options="users"></vue-select>
        </div>
        <div class="input-field col s12">
             <ValidationProvider rules="required" immediate v-slot="{ errors }">
                <input id="app" type="text" v-model="item.app" class="validate" :class="{'invalid':errors.length>0,'valid':errors.length==0}">
                <span v-for="(e,idx) in errors" :key="idx"  class="helper-text validation-error-message">{{ e }}</span>
            </ValidationProvider>
            <label for="app" class="active">Application</label>
        </div>
        <div class="input-field col s12">
            <label for="roleId" class="active">Role</label>
            <vue-select v-model="item.roleId" :reduce="(v)=>{return v.id}" label="name" :options="roles"></vue-select>
        </div>
        <div class="col s12">
            <div class="controls">
                <a class="waves-effect waves-light btn right" v-on:click="onSave"><i class="material-icons">save</i></a>
                <a class="waves-effect waves-light btn right" v-on:click="onCancel" style="margin-right:10px;"><i class="material-icons">cancel</i></a>
            </div>
        </div>
    </div>
    </ValidationObserver>
</div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import _ from 'underscore';

import { ValidationProvider,ValidationObserver } from 'vee-validate';

import globals from '../globals.js';
import settings from '../settings.js'

const getItemURL=`${settings.serviceUrl}approle/get`;
const updateItemURL=`${settings.serviceUrl}approle/update`;
const defaultReturnURL='/approles';

export default {
    props: {
        id: {
            type: String,
            default: "-"
        },
    },
    watch:{},
    computed:{},
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            item: {},
            roles:_.filter(settings.roles,(r)=>r.app!==''),
            users:[]
        };
    },
    mounted() {
        var that = this;
        
        const action=async ()=>{
            this.$root.$emit('ui-blocker',1);
            let usersResponse=await axios.post(`${settings.serviceUrl}user/getMany`, {tokenValue: window.localStorage.getItem("token"),sort:[{field:'email',direction:'asc'}]});
            this.users=usersResponse.data.payload.items;
            let itemResponse=await axios.post(getItemURL, {tokenValue: window.localStorage.getItem("token"),id: this.id});
            let item=itemResponse.data.payload;
            this.$root.$emit('ui-blocker',-1);
            this.item=item;
        };
        action();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.rootScreen = from.fullPath;
            if (vm.rootScreen=="/")
                vm.rootScreen=defaultReturnURL;
        });
    },
    methods: {
        onCancel() {
            this.$store.commit('setAdminEditPageId',this.item.id);
            this.$router.push(this.rootScreen)
        },
        async onSave() {
            let validationResponse=await this.$refs.validator.validate();
            if (!validationResponse){
                globals.dialog.alert('Validation errors','Some values are required or not valid');
                return
            }
            let response=await axios.post(updateItemURL, {tokenValue: window.localStorage.getItem("token"),item: this.item});
            this.$store.commit('setAdminEditPageId',this.item.id);
            this.$router.push(this.rootScreen)
        }
    }
};
</script>

<style lang="less">
</style>
