module.exports = {
    env:process.env.NODE_ENV || 'development',
    serviceUrl: 'https://auth-api.ipplos.com/',
    dataPerPage:100,
    roles:[
      {id:0,name:'admin',app:''},
      {id:1,name:'App defined',app:''},      
      {id:2,name:'admin - rpg',app:'rpg'},
      {id:3,name:'writer - rpg',app:'rpg'},   
      {id:4,name:'admin - site.api',app:'site.api'},
      {id:5,name:'writer - site.api',app:'site.api'},   
      {id:6,name:'admin - danae.gr',app:'danae.gr'},
      {id:7,name:'user - danae.gr',app:'danae.gr'},  
      {id:8,name:'admin - ais.app',app:'ais.app'},
      {id:9,name:'user - ais.app',app:'ais.app'}, 
    ],
    app:'auth.api',
    sentryKey: 'https://f3e90c6c889740b7b371bfc6d9ebb4e9@sentry.io/4051611'
  };
  