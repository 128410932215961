<template>
<div class="container">
    <div class="row">
        <div class="col s12">
            <div v-if="testing" style="font-weight:bold">Testing connection with database</div>
            <div v-else style="font-weight:bold">Connection with database result:</div>
            <div v-if="status===0">...</div>
            <div v-if="status===1" style="color:green;font-weight:bold">SUCCESS</div>
            <div v-if="status===-1" style="color:red;font-weight:bold">ERROR</div>
            <div v-if="message" v-html='message'></div>
        </div>
        <div class="col s12">
            <button class="btn right" @click="onRefresh">Refresh</button>
            
        </div>
        <div class="col s12">
            <div class="right">Last update: {{lastUpdate | toDateString}}</div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';

import settings from '../settings.js';

export default {
    filters:{
        toDateString(v){
            return moment(v).format('DD/MM/YYYY HH:mm:ss');
        }
    },
    data() {
        return {
            testing:true,
            status:0,
            message:'',
            lastUpdate:new Date()
        }
    },
    components:{},
    mounted() {
        const self = this;
        this.getInfo();
    },
    methods: {
        async getInfo(){
            this.testing=true;
            this.status=0;
            this.message='';
            await axios.post(`${settings.serviceUrl}testDBConnection`, {}).then((r) => {
                if (r.data.ok){
                    this.status=1;
                    this.testing=false;
                }
                else{
                    this.status=-1;
                    this.message=r.data.message+'<br/>'+JSON.stringify(r.data.payload);
                    this.testing=false;
                }
            });
            this.lastUpdate=new Date();
        },
        async onRefresh(){
            await this.getInfo();
        }
    }
}
</script> 

<style lang="less">

</style>
